<template>
  <div class="" id="year_select">
      <v-select class="year_select_dropdown" :options="options" v-model="optionSelected" :value="optionSelected" placeholder="Year" :clearable="false" :searchable="false" :components="{OpenIndicator}"/>
  </div>
</template>

<script>
import OpenIndicator from '@/components/OpenIndicator'

export default {
    name: 'YearSelect',
    props: {
        startYear: {
            type: Number
        },
        endYear: {
            type: Number
        },
        selected: {
            type: Function
        }
    },
    data(){
        return {
            optionSelected: '',
            OpenIndicator: {
                render: () => <OpenIndicator/> 
            }
        }
    },
    computed: {
        options: function(){
            // create an array of options for drop down list with starting year and ending year
            let optionsArray = new Array
            for(var i = this.startYear; i >= this.endYear; i-- ){
                optionsArray.push(i.toString())
            }
            return optionsArray
        }
    },
    watch: {
        optionSelected: function(newValue){
            this.$emit('selected', newValue)
        }
    }
}
</script>

<style>
#year_select{
    max-width: 125px;
}
.year_select_dropdown .vs__search{
    @apply py-2.5 pl-4 pr-0 sm:pl-2 sm:py-1 md:py-2.5 md:pl-4
}
.year_select_dropdown .vs__search:focus{
    @apply py-2.5 pl-4 pr-0 sm:pl-2 sm:py-1 md:py-2.5 md:pl-4
}
.year_select_dropdown .vs__selected{
    @apply font-koho pl-4 sm:pl-2 md:pl-4
}
.year_select_dropdown .vs__actions{
    @apply pr-4 sm:pr-2 md:pr-4
}
.year_select_dropdown .vs--single.vs--open .vs__selected{
    @apply hidden !important
}
.year_select_dropdown .vs__dropdown-option--highlight{
    @apply bg-mainColor 
}
.year_select_dropdown .vs__dropdown-menu{
    max-height: 150px;
}
.year_select_dropdown .vs__dropdown-option{
    @apply font-koho
}
.year_select_dropdown .vs__search::placeholder{
    @apply font-koho text-base text-inputBorderGray font-extralight
}
</style>
<template>
  <div class="" id="educational_qualification">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="educational_qualification_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Your educational qualifications
                </div>
                <div class="left_section_sub_para">
                    You can provide the details of your qualifications to potential employers when they reach out to you.                
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="3"/>
                <div class="input_heading">
                    What is your highest level of education?
                </div>
                <div class="options_wrapper">
                    <single-select :options="options" v-on:select="onClickSingleSelect"/>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import SingleSelect from '@/components/SingleSelect'
import API from '@/API'

export default {
    name: 'EducationalQualification',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'single-select': SingleSelect
    },
    data(){
        return{
            activateNext: false,
            options: [],
            optionSelected: -1,
            mob: 0
        }
    },
    methods: {
        onClickNext: function(){
            // set data
            var data = {
                mobile: parseInt(this.mob),
                education: this.options[this.optionSelected]
            }
            // save the form 
            fetch(API+'/jobseeker/education',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path:'/jobseeker_register/work_experience', query:{mob: this.mob}})
                }else{
                    // remove the selection
                    this.optionSelected = -1
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onClickSingleSelect: function(selected){
            this.optionSelected = selected
        },
        setData: function(options){
            // set options
            this.options = options
        }
    },
    watch: {
        optionSelected: function(old){
            if(old !== -1){
                this.activateNext = true
            }else{
                this.activateNext = false
            }
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
        });
    },
    beforeRouteEnter (to, from, next) {
        // get the options from backend
        fetch(API+'/render/education')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.education.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var options = []
            data.education.map((item)=>{
                options.push(item.option)
            })
            // render component
            next(vm => {vm.setData(options)})
        })
        .catch((err)=>{
            console.log(err)
        })
        
    }
}
</script>

<style>
#educational_qualification{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.options_wrapper{
    @apply mt-6;
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
</style>
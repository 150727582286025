<template>
  <div class="h-screen w-screen flex flex-col">
    <div class="header fixed">
      <Header menuColor="#A51111"/>
    </div>
    <div class="content h-full w-full flex flex-col justify-start items-center sm:justify-center">
        <div class=" mt-52 text-center sm:mt-0">
            <h1 class="text-7xl font-koho text-customRed md:text-9xl" id="error_code">404</h1>
            <h1 class="font-koho text-black md:text-3xl" id="error_tag">Page Not Found</h1>
        </div>
    </div>
    <div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
    components:{
        Header,
        Footer
    }
}
</script>

<style>

</style>
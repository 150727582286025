<template>
  <div class="" id="verification">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="verification_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Verification
                </div>
                <div class="left_section_sub_para">
                    To ensure that we have your correct mobile number, we have sent you an OTP.                
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="9"/>
                <div id="otp_section">
                    <div class="input_heading">
                        Please enter the OTP sent to your mobile
                    </div>
                    <div class="otp_input_wrapper">
                        <otp-input :error="otpError" @completed="onCompleteOtp" @change="onChangeOtp" :verified="verified"/>
                    </div>
                    <div class="timer_resend_section mt-2 flex flex-row items-center absolute" :class="{hidden: !showTimer}">
                        <div class="count_down pt-0.5">
                            <timer @onEnd="onEndTimer" @onChange="onChangeTimer" :reset="resetTimer"/>
                        </div>
                        <div class="resend" :class="{hidden: !showResend}">
                            <button class="resend_button focus:outline-none font-koho underline ml-2 text-sm text-mainColor active:text-inputBorderGray" @click="onClickResend">Resend</button>
                        </div>
                    </div>
                </div>
                <div id="referral_section">
                    <div class="input_heading">
                        How did you hear of Strong United?
                    </div>
                    <div class="options_wrapper">
                        <single-select-drop-down :options="referralOptions" v-on:selected="onSelectReferral" placeholder="Choose one"/>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickComplete" :active="activateComplete" text="COMPLETE"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import SingleSelectDropDown from '@/components/SingleSelectDropDown'
import OTPInput from '@/components/OTPInput'
import API from '@/API'
import Timer from '@/components/Timer'

export default {
    name: 'Verification',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'single-select-drop-down': SingleSelectDropDown,
        'otp-input': OTPInput,
        'timer': Timer
    },
    data(){
        return{
            referralOptions: [],
            referralSelected: '',
            otp: '',
            mob: 0,
            timer: 0,
            showTimer: false,
            showResend: false,
            resetTimer: false,
            verified: false,
            otpError: false,
            tries: 0,
        }
    },
    watch: {
        timer: function(newValue){
            if(newValue < 120 && newValue > 0){
                this.showResend = true
            }
            // if value is 180 set resetTimer as false
            if(newValue > 170){
                this.showResend = false
                this.resetTimer = false
            }
        }
    },
    methods: {
        onClickComplete: function(){
            // set data
            var data = {
                mobile: parseInt(this.mob),
                referrer: this.referralSelected
            }
            // save the form 
            fetch(API+'/jobseeker/referrer',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path:'/jobseeker_register/registration_complete'})
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onSelectReferral: function(selected){
            this.referralSelected = selected
        },
        onCompleteOtp: function(value){
            if(this.tries <= 2){
                // when 4 digits are completed
                this.otp = value
                // verify otp
                var data = {
                    mobile: parseInt(this.mob),
                    otp: this.otp
                }
                fetch(API+'/otp/validate',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(response => response.json())
                .then(data => {
                    if(data.success === true){
                        this.verified = true
                        this.showTimer = false
                    }else{
                        this.otpError = true
                        this.tries ++
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }else{
                // show error
                this.otpError = true
            }
            
        },
        onChangeOtp: function(value){
            // This function is invoked on each digit input
            this.otp = value
            this.otpError = false
        },
        sendOtp: function(mob){
            var data = {
                mobile: parseInt(mob)
            }
            // send otp
            fetch(API+'/otp/generate',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.showTimer = true
                    this.resetTimer = true
                    this.tries = 0
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onEndTimer: function(){
            console.log("hello")
        },
        onChangeTimer: function(value){
            this.timer = value
        },
        onClickResend: function(){
            if(this.verified !== true){
                this.sendOtp(this.mob)
            }
        },
        setData: function(options){
            // set options
            this.referralOptions = options
        }
    },computed:{
        activateComplete: function(){
            if( this.referralSelected.length > 0 && this.verified === true){
                return true
            }{
                return false
            }
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
            // send otp when page loaded
            this.sendOtp(this.mob)
        });
    },
    beforeRouteEnter (to, from, next) {
        // get the options from backend
        fetch(API+'/render/referrer')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.Referrer.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var options = []
            data.Referrer.map((item)=>{
                options.push(item.referral)
            })
            // render component
            next(vm => {vm.setData(options)})
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style>
#verification{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
#referral_section {
    @apply mt-14
}
.otp_input_wrapper{
    @apply mt-4
}
</style>
<template>
    <div id="job_posts">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111" button="sign-out"/>
        </div>
        <div class="register_content" id="job_posts_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Your job posts
                </div>
                <div v-if="hasJobs">
                    <div class="new_job_post_button">
                        <green-button :onClick="onClickNewJobPost" :active="newJobPostButtonActive" text="NEW JOB POST"/>
                    </div>
                    <div class="left_section_sub_para">
                        Job posts will automatically expire after 30 days. 
                        Matching profiles will be emailed to you.
                    </div>
                    <div class="jobs_section">
                        <div v-for="(item,index) in job_posts" :key="index">
                            <job @onClickDelete="onClickDelete(index)" :heading="item.title"  :date="new Date(item.created_at)" :active="(active_job === index)" @onClickButton="onClickJob(index)"/>
                        </div>
                    </div>
                </div>
                <div v-if="!hasJobs">
                    <div class="left_section_sub_para">
                        <span class="text-mainColor">You have not posted any jobs yet!</span>
                        <br>
                        Use the form on the right to create a job.
                    </div>
                </div>
            </div>
            <div class="right_section">
                <div v-if="show_job_details && hasJobs">
                    <job-details 
                        :title="job_posts[active_job].title ? job_posts[active_job].title : ''"
                        :gross_salary="job_posts[active_job].monthly_salary ? job_posts[active_job].monthly_salary : '' "
                        :industries="job_posts[active_job].industries ? job_posts[active_job].industries : ''"
                        :job_description="job_posts[active_job].description ? job_posts[active_job].description : ''"
                    />
                </div>
                <div v-if="!hasJobs || !show_job_details">
                    <job-details-form @onSave="onSaveJob"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NextButton from '@/components/NextButton'
import JobPost from '@/components/JobPost'
import JobDetails from '@/components/JobDetails'
import JobDetailsForm from '@/components/JobDetailsForm'
import API from '@/API'

export default {
    name: 'JobPosts',
    components: {
        Header,
        Footer,
        'green-button': NextButton,
        'job': JobPost,
        'job-details': JobDetails,
        'job-details-form': JobDetailsForm
    },
    data(){
        return {
            job_posts: new Array,
            active_job: 0,
            show_job_details: true
        }
    },
    computed: {
        hasJobs: function(){
            // if already jobs with the employer return true
            if(this.job_posts.length > 0){
                return true
            }else{
                return false
            }
        },
        newJobPostButtonActive: function(){
            if(this.hasJobs && this.show_job_details){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        onClickNewJobPost: function(){
            // show job details screen
            this.show_job_details = false
            // set active index -1
            this.active_job = -1
        },
        onClickDelete: function(index){
            // set data
            let data = {
                title: this.job_posts[index].title,
                description: this.job_posts[index].description
            }
            // remove the specific record
            fetch(API+'/employer/job_post/remove',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Authorization': 'Bearer ' + localStorage.access_token,
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    // delect the record
                    this.job_posts.splice(index, 1)
                    // set active job to 0
                    this.active_job = 0
                }else{
                    // reset token
                    localStorage.access_token = ''
                    // navigate back
                    this.$router.push('/for_employers')
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        setData(job_posts){
            // set the state
            this.job_posts = job_posts
        },
        onClickJob: function(x){
            // show job detals
            this.show_job_details = true
            // set the current job
            this.active_job = x
        },
        onSaveJob: function(record){
            // get all the jobs posted before
            fetch(API+'/employer/job_post/add',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Authorization': 'Bearer ' + localStorage.access_token,
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(record)
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    let industries = ''
                    record.industries.forEach((item)=>{
                        if(industries === ''){
                            industries = item
                        }else{
                            industries = industries + ', ' + item
                        }
                    })
                    // change the industries
                    record.industries = industries
                    // set job post time
                    record.created_at = new Date(Date.now())
                    // push the new job to the list
                    this.job_posts.push(record)
                    // change the current active record as the new record
                    this.active_job = this.job_posts.length - 1
                    // show details
                    this.show_job_details = true
                }else{
                    // reset token
                    localStorage.access_token = ''
                    // navigate back
                    this.$router.push('/for_employers')
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    },
    mounted(){
        // if there is no access tken then navigate back to for employers
        if(localStorage.access_token === ''){
            this.$router.push('/for_employers')
        }
    },
    beforeRouteEnter (to, from, next){
        // get all the jobs posted before
        fetch(API+'/employer/job_post/get_all',
        {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
            'Authorization': 'Bearer ' + localStorage.access_token,
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.success === true){
                next(vm => {vm.setData(data.job_posts)})
            }else{
                // reset token
                localStorage.access_token = ''
                // navigate back
                this.$router.push('/for_employers')
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style>
#job_posts{
    @apply md:min-h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.new_job_post_button{
    @apply mt-5 max-w-xs;
    @apply lg:mt-10;
}
.jobs_section{
    @apply mt-0;
    @apply lg:mt-8;
}
#job_posts_content{
    @apply md:py-16 md:items-start
}
#job_posts .right_section{
    @apply mt-10;
    @apply md:mt-4;
    @apply lg:mt-5;
}
</style>
<template>
  <div class="" id="single_select_drop_down">
      <v-select class="dropdown" :options="options" v-model="optionSelected" :value="optionSelected" :placeholder="placeholder" :clearable="false" :searchable="false" :components="{OpenIndicator}"/>
  </div>
</template>

<script>
import OpenIndicator from '@/components/OpenIndicator'

export default {
    name: 'SingleSelectDropDown',
    props: {
        options: {
            type: Array
        },
        placeholder: {
            type: String
        }
    },
    data(){
        return {
            optionSelected: '',
            OpenIndicator: {
                render: () => <OpenIndicator/> 
            }
        }
    },
    watch: {
        optionSelected: function(newValue){
            this.$emit('selected', newValue)
        }
    }
}
</script>

<style>
#single_select_drop_down{
    @apply w-full max-w-xs
}
.dropdown .vs__search{
    @apply py-2.5 pl-4 pr-0 sm:pl-2 sm:py-1 md:py-2.5 md:pl-4
}
.dropdown .vs__search:focus{
    @apply py-2.5 pl-4 pr-0 sm:pl-2 sm:py-1 md:py-2.5 md:pl-4
}
.dropdown .vs__selected{
    @apply font-koho pl-4 sm:pl-2 md:pl-4
}
.dropdown .vs__actions{
    @apply pr-4 sm:pr-2 md:pr-4
}
.dropdown .vs--single.vs--open .vs__selected{
    @apply hidden !important
}
.dropdown .vs__dropdown-option--highlight{
    @apply bg-mainColor 
}
.dropdown .vs__dropdown-menu{
    max-height: 150px;
}
.dropdown .vs__dropdown-option{
    @apply font-koho
}
.dropdown .vs__search::placeholder{
    @apply font-koho text-base text-inputBorderGray font-extralight
}
</style>
<template>
  <div class="job_details">
      <div class="title_section">
            <div class="heading">
                Job title
            </div>
            <div class="section_content">
                {{title}}
            </div>
      </div>
      <div class="gross_salary_section section">
            <div class="heading">
                Monthly gross salary
            </div>
            <div class="section_content">
                {{gross_salary}}
            </div>
      </div>
      <div class="industries_section section">
            <div class="heading">
                Industry your business is in
            </div>
            <div class="section_content">
                {{industries}}
            </div>
      </div>
      <div class="job_description_section section">
            <div class="heading">
                Job description
            </div>
            <div class="section_content">
                {{job_description}}
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'JobDetails',
    props: {
        title: {
            type: String
        },
        gross_salary: {
            type: String
        },
        industries: {
            type: String
        },
        job_description:{
            type: String
        }
    }
}
</script>

<style>
.job_details{
    @apply mt-20 md:mt-0;
}
.section {
    @apply mt-5;
}
.heading {
    @apply text-black;
}
.section_content{
    @apply font-koho text-customRed mt-1 md:mt-3 ;
}
</style>
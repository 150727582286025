<template>
  <div id="for_employers_logo_section">
      <div class="main_heading">
          You are in good company
      </div>
      <div class="logo_section">
          <div v-for="index in (Math.ceil(logos.length/3))" :key="index" class="logo_block">
            <img :src="logos[(index - 1)*3]" alt="" class="logo" :class="{hidden: ((index - 1)*3) > (logos.length - 1)}">
            <img :src="logos[((index - 1)*3) + 1]" alt="" class="logo" :class="{hidden: (((index - 1)*3) + 1) > (logos.length - 1)}">
            <img :src="logos[((index - 1)*3) + 2]" alt="" class="logo" :class="{hidden: (((index - 1)*3) + 2) > (logos.length - 1)}">
          </div>
      </div>
  </div>
</template>

<script>
import API from '@/API'
export default {
    name: 'ForEmployersLogoSection',
    data(){
        return {
            logos: []
        }
    },
    beforeMount(){

        fetch(API+'/render/company_logos')
        .then(response => response.json())
        .then(data => {
            if(data.success === true){
                this.logos = data.links
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    
    }
}
</script>

<style>
#for_employers_logo_section{
    @apply w-full h-auto flex flex-col justify-center items-center px-5 py-20;
    @apply sm:px-10;
    @apply lg:px-20;
}
#for_employers_logo_section .main_heading{
    @apply text-4xl font-koho text-center;
    @apply md:text-5xl;
    @apply lg:text-6xl;
}
#for_employers_logo_section .logo_section{
    @apply mt-5 w-auto;
    @apply sm:w-full sm:mt-0;
}
#for_employers_logo_section .logo{
    @apply w-auto h-16 mt-10;
    @apply sm:h-14;
    @apply md:h-16;
    @apply lg:h-20;
}
#for_employers_logo_section .logo_block{
    @apply flex flex-col justify-evenly w-full;
    @apply sm:flex-row sm:mt-8;
    @apply md:mt-10;
    @apply lg:mt-16;
}
</style>
<template>
  <div id="single_select_two_row">
      <div v-for="(item, index) in options" :key="index" class="single_select_element" :class="{'ml-12 sm:ml-16 md:ml-20 lg:ml-24': index===1}">
          <div class="radio_wrapper">
            <custom-radio type="radio" :bindedVal="selected" :id="index" :value="item" @bindedValChange="onChangeRadio"/>
          </div>
          <div class="single_select_element_label" :class="{active_label : selected === item}" @click="onClickLabel(item)">
              {{item}}
          </div>
      </div>
  </div>
</template>

<script>
import CustomRadio from '@/components/CustomRadio'

export default {
    name: 'SingleSelectTwoRow',
    components: {
        'custom-radio': CustomRadio
    },
    props: {
        options: {
            type: Array
        },
        displayRow: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            selected: ''
        }
    },
    methods: {
        onChangeRadio: function(value){
            this.selected = value
            this.$emit('select', this.options.indexOf(this.selected))
        },
        onClickLabel: function(item){
            // when user clicks on the label instead of radio button
            // set selected as clicked item
            this.selected = item
            // selected item change has to be passed to the parent
            this.$emit('select', this.options.indexOf(this.selected))
        }
    }
}
</script>

<style>
#single_select_two_row{
    @apply flex flex-row
}
#single_select_two_row .single_select_element{
    @apply flex flex-row items-center
}
#single_select_two_row .radio_wrapper{
    @apply mt-0.5
}
#single_select_two_row .single_select_element_label{
    @apply font-koho ml-2 text-base cursor-pointer active:bg-mainColor active:text-white px-2 rounded-sm;
    @apply text-lg;
}
#single_select_two_row .active_label{
    @apply text-mainColor
}
</style>
<template>
  <div class="" id="personal_information">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="personal_information_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Personal information
                </div>
                <div class="left_section_sub_para">
                    This information is not shared automatically with employers unless you explicitly give your consent.                
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="7"/>
                <div id="gender_section">
                    <div class="input_heading">
                        What is your gender?
                    </div>
                    <div class="options_wrapper">
                        <single-select :options="options" v-on:select="onClickSingleSelect"/>
                    </div>
                </div>
                <div id="pincode_year_wrapper">
                    <div id="pincode_section">
                        <div class="input_heading">
                            Your location PIN code
                        </div>
                        <input type="text" class="inputbox" id="pincode_input" placeholder="Ex: 110001" v-bind:value="pincode" v-on:input="onInputPincode"  :class="{error_box: errorPincode.error}">
                        <h3 class="error_message_absolute" :class="{hidden: !errorPincode.error}">{{errorPincode.msg}}</h3>
                    </div>
                    <div id="year_section">
                        <div class="input_heading">
                            Your year of birth
                        </div>
                        <div class="year_select_section">
                            <year-select :startYear="startYear" :endYear="endYear" @selected="onSelectYear"/>
                        </div>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import SingleSelect from '@/components/SingleSelect'
import yearSelect from '@/components/YearSelect'
import API from '@/API'

export default {
    name: 'PersonalInformation',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'single-select': SingleSelect,
        'year-select': yearSelect
    },
    data(){
        return{
            options: ['Female', 'Male', 'Other'],
            optionSelected: -1,
            pincode: '',
            errorPincode: {
                error: false,
                msg: ''
            },
            yearOfBirth: 0,
            mob: 0,
        }
    },
    methods: {
        onClickNext: function(){
            // set data
            var data = {
                mobile: parseInt(this.mob),
                year: this.yearOfBirth,
                pincode: this.pincode,
                gender: this.options[this.optionSelected]
            }
            // save the form 
            fetch(API+'/jobseeker/personal_information',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path:'/jobseeker_register/job_preferences/', query:{mob: this.mob}})
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onClickSingleSelect: function(selected){
            this.optionSelected = selected
        },
        onSelectYear: function(year){
            this.yearOfBirth = parseInt(year)
        },
        onInputPincode: function(e){
            //stop the input when input length reaches 10
            if(e.target.value.replace(/\D/g,'').length <= 6){
                this.pincode = e.target.value.replace(/\D/g,'')
            }
            // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
            this.$forceUpdate();
        },
    },computed:{
        activateNext: function(){
            if(this.optionSelected !== -1 && this.pincode.length === 6 && this.yearOfBirth > 0){
                return true
            }else{
                return false
            }
        },
        startYear: function(){
            // return current year - 17
            var dt = new Date(Date.now())
            return dt.getFullYear() - 17
        },
        endYear: function(){
            // return current - 70
            var dt = new Date(Date.now())
            return dt.getFullYear() - 70
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
        });
    }
}
</script>

<style>
#personal_information{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
.year_select_section{
    @apply mt-2
}
#pincode_year_wrapper{
    @apply flex flex-col md:flex-row
}
#pincode_section{
    @apply mt-6 max-w-xs
}
#year_section{
    @apply mt-6 w-full md:ml-6 lg:ml-8
}
.options_wrapper{
    @apply mt-4
}
</style>
<template>
  <div class="" id="language_proficiency_read_write">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="language_proficiency_read_write_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Language proficiency
                </div>
                <div class="left_section_sub_para">
                    Knowledge of more than one language may be an advantage.                
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="6"/>
                <div class="input_heading">
                    Which of these languages can you <span class="text-customRed">READ & WRITE ?</span>
                </div>
                <div id="languages">
                        <multiple-select :options="optionsOne" v-on:select="onSelectListOne" :key="0"/>
                    <div class="section_two">
                        <multiple-select :options="optionsTwo" v-on:select="onSelectListTwo" :key="1"/>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import MultipleSelect from '@/components/MultipleSelect'
import API from '@/API'

export default {
    name: 'LanguageProficiencyReadWrite',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'multiple-select': MultipleSelect
    },
    data(){
        return{
            optionsEnglish: [],
            optionsLang: [],            
            optionsSelectedOne: new Array(0),
            optionsSelectedTwo: new Array(0),
        }
    },
    computed: {
        optionsOne: function(){
            var temp_options = this.optionsLang
            return temp_options.slice(0, Math.ceil(this.optionsLang.length/2))
        },
        optionsTwo: function(){
            var temp_options = this.optionsLang
            return temp_options.slice(Math.ceil(this.optionsLang.length/2), this.optionsLang.length)
        },
        // activate next button when any of the two list selected
        activateNext: function(){
            if(this.optionsSelectedOne.length > 0 || this.optionsSelectedTwo.length > 0){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        onClickNext: function(){
            var written_languages = []
            this.optionsSelectedOne.map((item)=>{
                written_languages.push(this.optionsEnglish[item])
            })
            this.optionsSelectedTwo.map((item)=>{
                written_languages.push(this.optionsEnglish[item + this.optionsOne.length])
            })
            // set data
            var data = {
                mobile: parseInt(this.mob),
                written_languages: written_languages
            }
            // save the form 
            fetch(API+'/jobseeker/written_languages',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path:'/jobseeker_register/personal_info', query:{mob: this.mob}})
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onSelectListOne: function(checked){
            // update optionsSelected based on the checked array
            let customThis = this
            checked.map((item, key)=>{
                // check if the key is already in optionsSelected
                if(customThis.optionsSelectedOne.find(element=>element===key) === undefined && item === true){
                    customThis.optionsSelectedOne.push(key)
                }
                // delete the key if the value become false
                if(customThis.optionsSelectedOne.find(element=>element===key) !== undefined && item === false){
                    customThis.optionsSelectedOne.splice(customThis.optionsSelectedOne.indexOf(key), 1)
                }
            })
        },
        onSelectListTwo: function(checked){
            // update optionsSelected based on the checked array
            let customThis = this
            checked.map((item, key)=>{
                // check if the key is already in optionsSelected
                if(customThis.optionsSelectedTwo.find(element=>element===key) === undefined && item === true){
                    customThis.optionsSelectedTwo.push(key)
                }
                // delete the key if the value become false
                if(customThis.optionsSelectedTwo.find(element=>element===key) !== undefined && item === false){
                    customThis.optionsSelectedTwo.splice(customThis.optionsSelectedTwo.indexOf(key), 1)
                }
            })
        },
        setData: function(optionsEnglish, optionsLang){
            // set options
            this.optionsEnglish = optionsEnglish
            this.optionsLang = optionsLang
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
        });
    },
    beforeRouteEnter (to, from, next) {
        // get the options from backend
        fetch(API+'/render/languages')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.languages.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var optionsEnglish = []
            data.languages.map((item)=>{
                optionsEnglish.push(item.langNameEng)
            })
            var optionsLang = []
            data.languages.map((item)=>{
                optionsLang.push(item.langName)
            })
            // render component
            next(vm => {vm.setData(optionsEnglish, optionsLang)})
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style>
#language_proficiency_read_write{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.options_wrapper{
    @apply mt-6;
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
#languages{
    @apply flex flex-row
}
.section_two{
    @apply ml-12 sm:ml-16 md:ml-20 lg:ml-24
}
#languages{
    @apply mt-3
}
</style>
<template>
  <div id="for_employer_basic_info">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111" button="sign-out"/>
        </div>
        <div class="register_content" id="basic_info_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Looks like you are new here                
                </div>
                <div class="left_section_sub_heading">
                    THIS IS A FREE SERVICE
                </div>
                <div class="left_section_sub_para">
                    Please register to post your work-from-home job oppotunities and find matching candidates.
                </div>
            </div>
            <div class="right_section">
                <div class="" id="basic_info_inputs">
                    <div class="flex flex-col md:flex-row md:items-center">
                        <div class="" id="first_name_section">
                            <div class="input_heading">
                                Your first name
                            </div>
                            <input type="text" class="inputbox" id="first_name_input" placeholder="First name" v-model="first_name" :class="{error_box: errorFirstName.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorFirstName.error}">{{errorFirstName.msg}}</h3>
                        </div>
                        <div class="" id="last_name_section">
                            <div class="input_heading">
                                Your last name
                            </div>
                            <input type="text" class="inputbox" id="last_name_input" placeholder="Last name" v-model="last_name" :class="{error_box: errorLastName.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorLastName.error}">{{errorLastName.msg}}</h3>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:items-center">
                        <div class="" id="email_section">
                            <div class="input_heading">
                                Your business email address
                            </div>
                            <input type="text" class="inputbox" id="email_input" placeholder="Email address" v-model="email"  :class="{error_box: errorEmail.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorEmail.error}">{{errorEmail.msg}}</h3>
                        </div>
                        <div class="" id="organization_section">
                            <div class="input_heading">
                                Your organization
                            </div>
                            <input type="text" class="inputbox" id="organization_input" placeholder="Name of business" v-model="organization" :class="{error_box: errorOrganization.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorOrganization.error}">{{errorOrganization.msg}}</h3>
                        </div>
                    </div>
                </div>
                <div class="" id="consent">
                    <div class="mt-1.5"><custom-checkbox type="checkbox" v-model="checked" /></div>
                    <div :class="{consent_error: errorChecked.error}" id="consent_heading">
                    You agree to be contacted by our volunteers over phone, SMS, and / or email. Your contact information will NOT be sold to or shared with any third party.                    </div>
                </div>
                <div id="next_button_section" class="md:max-w-xs">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import NextButton from "@/components/NextButton"
import CustomCheckbox from '@/components/CustomCheckbox'
import API from '../../API'

export default {
    name: "ForEmployerBasicInfo",
    components: {
        Header,
        Footer,
        'next-button': NextButton,
        'custom-checkbox': CustomCheckbox
    },
    data(){
        return {
            checked: false,
            first_name: "",
            last_name: "",
            organization: "",
            email: "",
            errorFirstName: {
                error: false,
                msg: ""
            },
            errorLastName: {
                error: false,
                msg: ""
            },
            errorOrganization: {
                error: false,
                msg: ""
            },
            errorEmail: {
                error: false,
                msg: ""
            },
            errorChecked: {
                error: false
            },
            activateNext: false
        }
    },
    computed: {
        activateNextButton: function(){
            return this.first_name,this.last_name,this.email,this.mob,this.checked, Date.now();
        }
    },
    methods: {
        onClickNext: function(){
            // set data
            let data = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                organization: this.organization
            }
            // save basic info
            fetch(API+'/employer/basic_info',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Authorization': 'Bearer ' + localStorage.access_token,
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path: '/for_employers/email_verification', query:{email: this.email}})
                }
            })
            .catch((err)=>{
                console.log(err)
            })
            
        },
        onInputMob: function(e){
            //stop the input when input length reaches 10
            if(e.target.value.replace(/\D/g,'').length <= 10){
                // strip characters
                var strippedMob = e.target.value.replace(/\D/g,'')
                // if the first value is 0, delete it
                if(strippedMob[0] === '0'){
                    // slice the 0 
                    strippedMob =  strippedMob.slice(1)
                }
                this.mob = strippedMob
            }
            // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
            this.$forceUpdate();
        },
        validateFirstName: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.first_name === ""){
                this.errorFirstName.error = true
                this.errorFirstName.msg = "Enter your first name"
                return false
            }else if(specialCharacterFormat.test(this.first_name) || digitFormat.test(this.first_name)){
                this.errorFirstName.error = true
                this.errorFirstName.msg = "Enter a valid name"
                return false
            }else{
                this.errorFirstName.error = false
                this.errorFirstName.msg = ""
                return true
            }
        },
        validateLastName: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.last_name === ""){
                this.errorLastName.error = true
                this.errorLastName.msg = "Enter your last name"
                return false
            }else if(specialCharacterFormat.test(this.last_name) || digitFormat.test(this.last_name)){
                this.errorLastName.error = true
                this.errorLastName.msg = "Enter a valid name"
                return false
            }else{
                this.errorLastName.error = false
                this.errorLastName.msg = ""
                return true
            }
        },
        validateOrganization: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.organization === ""){
                this.errorOrganization.error = true
                this.errorOrganization.msg = "Enter your organization"
                return false
            }else if(specialCharacterFormat.test(this.organization) || digitFormat.test(this.organization)){
                this.errorOrganization.error = true
                this.errorOrganization.msg = "Enter a valid organization"
                return false
            }else{
                this.errorOrganization.error = false
                this.errorOrganization.msg = ""
                return true
            }
        },
        validateEmail: function(){
            // email format in regex code
            var validEmailFormat  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if(this.email === ""){
                this.errorEmail.error = true
                this.errorEmail.msg = "Enter your email"
                return false
            }else if(!validEmailFormat.test(this.email)){
                this.errorEmail.error = true
                this.errorEmail.msg = "Enter a valid email"
                return false
            }else{
                this.errorEmail.error = false
                this.errorEmail.msg = ""
                return true
            }
        },
        validateChecked: function(){
            if(this.checked === false){
                this.errorChecked.error = true
                return false
            }else{
                this.errorChecked.error = false
                return true
            }
        }
    },
    watch: {
        first_name: function(){
            this.validateFirstName()
        },
        last_name: function(){
            this.validateLastName()
        },
        email: function(){
            this.validateEmail()
        },
        organization: function(){
            this.validateOrganization()
        },
        checked: function(){
            this.validateChecked()
        },
        activateNextButton: function(){
            // check if there is no error
            if(!this.errorFirstName.error && !this.errorLastName.error && !this.errorOrganization.error && !this.errorEmail.error && !this.errorChecked.error){
                // check if all the feilds are filled
                if(this.first_name !== "" && this.last_name !== "" && this.email !== "" && this.organization !== "" && this.checked == true){
                    this.activateNext = true
                }
            }else{
                this.activateNext = false
            }
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        // if there is no access tken then navigate back to for employers
        if(localStorage.access_token === ''){
            this.$router.push('/for_employers')
        }
    }
}
</script>

<style scoped>
#for_employer_basic_info{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
#header{
    @apply w-full h-auto min-h-screen;
}
#left_section_heading{
    @apply font-koho text-xl;
}
#last_name_section{
    @apply mt-6;
    @apply md:mt-0 md:ml-5;
    @apply lg:ml-10;
}
#email_section{
    @apply mt-6;
    @apply md:mt-8;
    @apply lg:mt-10;
}
#organization_section{
    @apply mt-6;
    @apply md:ml-5 md:mt-8;
    @apply lg:ml-10 lg:mt-10;
}
#disclaimer{
    @apply mt-10 font-koho text-xs;
}
#next_button_section{
    @apply mt-12;
}
#for_employer_basic_info #consent{
    @apply flex flex-row mt-10 items-start;
}
#consent_heading{
    @apply font-koho text-xs font-medium ml-3;
}
.consent_error{
    @apply text-customRed !important;
}
#for_employer_basic_info #basic_info_inputs{
    @apply mt-10;
    @apply sm:mt-0
}
</style>
<template>
  <div id="for_employers_main_section">
      <div id="for_employers_main_section_content">
          <div class="left">
              <div class="sub_heading">
                  HARVARD BUSINESS REVIEW, 2020
              </div>
              <div class="main_heading">
                  “Now is an unprecedented opportunity to hire great talent”
              </div>
              <div class="text">
                  If <span class="text-mainColor-light">working from home</span> is a viable option for your business, a whole new workforce has just become available.
              </div>
              <div class="sub_text">
                  We will help you find them. <span class="font-semibold">For free.</span>
              </div>
          </div>
          <div class="right">
              <for-employers-sign-up/>
          </div>
      </div>
  </div>
</template>

<script>
import ForEmployersSignUp from '@/components/ForEmployersSignUp'

export default {
    name: "ForEmployersMainSection",
    components: {
        'for-employers-sign-up': ForEmployersSignUp
    }
}
</script>

<style>
#for_employers_main_section{
    @apply w-full min-h-screen flex h-auto; 
}
#for_employers_main_section_content{
    @apply w-full min-h-full h-auto flex flex-col justify-center items-center px-5 py-20;
    @apply sm:px-10 sm:flex-row;
    @apply lg:px-20;
}
#for_employers_main_section_content .left{
    @apply  h-auto max-w-sm mt-12;
    @apply lg:max-w-md;
}
#for_employers_main_section_content .right{
    @apply  h-auto w-full mt-16 max-w-sm;
    @apply sm:ml-10;
    @apply lg:max-w-md lg:ml-20;
}
#for_employers_main_section_content .left .sub_heading{
    @apply font-koho font-normal text-sm text-customRed sm:text-xs md:text-sm lg:text-base;
}
#for_employers_main_section_content .left .main_heading{
    @apply font-koho text-3xl text-black font-medium sm:text-2xl md:text-3xl lg:text-4xl lg:font-normal;
}
#for_employers_main_section_content .left .text{
    @apply text-inputBorderGray-dark pt-4 md:pt-7 lg:pt-8 font-koho font-extralight text-base sm:text-sm md:text-base max-w-xs lg:max-w-md lg:text-xl;
}
#for_employers_main_section_content .left .sub_text{
    @apply pt-2 md:pt-2 lg:pt-3 font-koho font-extralight text-base sm:text-sm md:text-base max-w-xs lg:max-w-md lg:text-xl;
}
</style>
<template>
  <div id="multiple_select">
      <div id="multiple_select_content">
          <div v-for="(item, index) in options" class="multiple_select_element" :key="index">
              <div class="checkbox_wrapper">
                  <custom-checkbox type="checkbox" v-model="checked[index]"/>
              </div>
              <div class="multiple_select_element_label" :class="{active_label: checked[index]}" @click="onClickLabel(index)">
                  {{item}}
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import CustomCheckbox from '@/components/CustomCheckbox'

export default {
    name: 'MultipleSelect',
    components: {
        'custom-checkbox': CustomCheckbox
    },
    props:{
        options: {
            type: Array
        },
        onCheck: {
            type: Function
        }
    },
    data(){
        return {
            checked : new Array
        }
    },
    created(){
        this.options.map(()=>{
            this.checked.push(false)
        })
    },
    watch: {
        checked: function(){
            this.$emit('select', this.checked)
        },
        options: function(){
            this.checked.splice(0, this.checked.length)
            // reset checked array
            this.options.map(()=>{
                this.checked.push(false)
            })
        }
    },
    methods: {
        onClickLabel: function(index){
            // when user clicks on the label
            // toggle bolean at that index
            this.checked.splice(index, 1, !this.checked[index])
        }
    }
}
</script>

<style>
.multiple_select_element{
    @apply flex flex-row items-center mt-4
}
.checkbox_wrapper{
    @apply mt-0.5
}
.multiple_select_element_label{
    @apply font-koho ml-2 text-base cursor-pointer active:bg-mainColor active:text-white px-2 rounded-sm;
    @apply text-lg;
}
.active_label{
    @apply text-mainColor
}
</style>
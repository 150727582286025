<template>
  <div id="for_employers_work_force_section">
      <div class="sub_heading">
        An untapped workforce is now available
      </div>
      <div class="main_heading">
        Work from home changes everything
      </div>
      <div class="employees_section">
        <div class="employee_block">
          <img class="employee_image" src="@/assets/images/employee_1.jpg" alt="">
          <div class="employee_name">
            Radhika C.
          </div>
          <div class="employee_story">
            When I became a mother, I thought I was taking a temporary break from my career as an HR comp & benefits specialist. Finding an opportunity after a break proved really hard.
          </div>
        </div>
        <div class="employee_block">
          <img class="employee_image" src="@/assets/images/employee_3.jpg" alt="">
          <div class="employee_name">
            Jiten M.
          </div>
          <div class="employee_story">
            I have been home-bound since a spinal injury made me immobile. I have been doing some freelance work as a data analyst for clients overseas, but I hope to find full-time jobs within India.
          </div>
        </div>
        <div class="employee_block">
          <img class="employee_image" src="@/assets/images/employee_2.jpg" alt="">
          <div class="employee_name">
            Catherine D.
          </div>
          <div class="employee_story">
            As a victim of domestic abuse, I chose to stand up for myself and my young son. I had an excellent record as a call center lead, but my life situation as single mother limits my options.
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#for_employers_work_force_section{
  @apply w-full min-h-screen h-full flex flex-col justify-center items-center bg-mainColor-superlight px-5 py-20;
  @apply sm:px-10;
  @apply lg:px-20;
}
#for_employers_work_force_section .employees_section{
  @apply flex flex-col mt-5;
  @apply sm:flex-row sm:mt-10;
  @apply md:mt-12;
  @apply lg:mt-16;
}
#for_employers_work_force_section .employee_block{
  @apply flex flex-col items-center px-10 max-w-xs mt-16;
  @apply sm:px-5 sm:mt-0 sm:mx-5;
}
#for_employers_work_force_section .employee_image{
  @apply w-44 h-auto border border-inputBorderGray;
}
#for_employers_work_force_section .sub_heading{
  @apply uppercase text-center font-medium;
}
#for_employers_work_force_section .main_heading{
  @apply text-4xl font-koho text-center mt-5;
  @apply sm:mt-2;
  @apply md:text-5xl;
  @apply lg:text-6xl;
}
#for_employers_work_force_section .employee_name{
  @apply font-koho font-semibold text-xl mt-5;
  @apply sm:text-base;
  @apply md:text-lg;
  @apply lg:text-xl;
}
#for_employers_work_force_section .employee_story{
  @apply font-koho mt-5;
  @apply sm:text-sm;
  @apply md:text-base;
}
</style>
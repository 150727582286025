<template>
  <div id="for_employers">
    <div class="header fixed z-50">
      <Header menuColor="#A51111" button="for-jobseekers"/>
    </div>
    <div id="for_employers_content">
        <main-section/>
        <for-employers-work-force-section/>
        <for-employers-logo-section/>
        <volunteer-section/>
        <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import VolunteerSection from '@/components/VolunteerSection'
import ForEmployersMainSection from '@/components/ForEmployersMainSection'
import ForEmployersWorkForceSection from '@/components/ForEmployersWorkForceSection'
import ForEmployersLogoSection from '@/components/ForEmployersLogoSection'

export default {
    name: 'ForEmployers',
    components: {
        Header,
        Footer,
        'volunteer-section': VolunteerSection,
        'main-section': ForEmployersMainSection,
        'for-employers-work-force-section': ForEmployersWorkForceSection,
        'for-employers-logo-section': ForEmployersLogoSection
    },
    mounted(){
      // scroll back to top when mounted
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
}
</script>

<style>

</style>
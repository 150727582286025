<template>
    <div id="radio_wrapper">
        <label>
            <input
                type="radio"
                v-bind:value="value"
                v-bind:checked="bindedVal === value"
                v-on:change="onSelect"
                id="custom_radio"
            >
            <span id="radio_border"></span>
            <span id="radio"></span>
        </label>
    </div>
    
</template>

<script>
export default {
    inheritAttrs: false,
    props:{
        value: {
            type: String
        },
        bindedVal: {
            type: String
        }
    },
    data(){
        return {
            selected: this.bindedVal
        }
    },
    methods: {
        onSelect: function(e){
            this.$emit('bindedValChange', e.target.value)
        }
    }
}
</script>

<style scoped>
#custom_radio{
    display: none;
}
#radio{
    @apply block w-3.5 h-3.5 border-2 border-white border-solid;
    border-radius: 7px; 
}
#custom_radio:checked ~ #radio{
    @apply bg-mainColor
}
#custom_radio:checked ~ #radio_border{
    @apply border-mainColor
}
#radio_wrapper{
    @apply h-4 w-4 relative
}
#radio_border{
    @apply border border-gray-400 h-4 w-4 absolute;
    top: -1px;
    left: -1px;
    border-radius: 8px;
}

</style>
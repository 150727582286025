<template>
  <div class="" id="registration_complete">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div id="registration_complete_content">
            <div id="registartion_complete_heading">
                Congratulations!
            </div>
            <div id="registartion_complete_sub_heading">
                Our volunteers will review your profile and forward it to matching work-from-home job opportunities. You can expect to hear from prospective employers in the next few days and weeks
            </div>
            <div id="next_button_wrapper">
                <next-button :onClick="onClickExit" :active="true" text="EXIT"/>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NextButton from '@/components/NextButton'

export default {
    name: 'RegistrationComplete',
    components: {
        Header,
        Footer,
        'next-button': NextButton,
    },
    methods: {
        onClickExit: function(){
            this.$router.push('/')
        }
    },
    mounted(){
        //var localThis = this
        // if back button is pressed
        /* window.onpopstate = function() {
            localThis.$router.push('/')
        } */
    }
}
</script>

<style>
#registration_complete{
    @apply flex flex-col sm:h-screen justify-center items-center 
}
#registration_complete_content{
    @apply w-full h-full bg-white flex flex-col justify-center items-center p-5 mt-32 max-w-sm sm:max-w-xl md:max-w-2xl lg:max-w-4xl
}
#next_button_wrapper{
    @apply w-full max-w-xs mt-8 md:mt-10 lg:mt-14
}
#registartion_complete_heading{
    @apply text-5xl font-koho md:text-5xl lg:text-6xl
}
#registartion_complete_sub_heading{
    @apply text-sm font-koho text-center  mt-8 md:text-base lg:text-xl md:mt-10 lg:mt-14
}
</style>
<template>
  <div class="" id="email_verification">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111" button="sign-out"/>
        </div>
        <div class="register_content" id="verification_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Please verify your business email
                </div>
                <div class="left_section_sub_para">
                    To continue, please enter the OTP sent to your email.            
                </div>
            </div>
            <div class="right_section">
                <div class="heading">
                    Verification
                </div>
                <div id="otp_section">
                    <div class="input_heading">
                        Please enter the OTP sent to your email
                    </div>
                    <div class="otp_input_wrapper">
                        <otp-input :error="otpError" @completed="onCompleteOtp" @change="onChangeOtp" :verified="verified"/>
                    </div>
                    <div class="timer_resend_section mt-2 flex flex-row items-center absolute" :class="{hidden: !showTimer}">
                        <div class="count_down pt-0.5">
                            <timer @onEnd="onEndTimer" @onChange="onChangeTimer" :reset="resetTimer"/>
                        </div>
                        <div class="resend" :class="{hidden: !showResend}">
                            <button class="resend_button focus:outline-none font-koho underline ml-2 text-sm text-mainColor active:text-inputBorderGray" @click="onClickResend">Resend</button>
                        </div>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickComplete" :active="activateComplete" text="NEXT"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NextButton from '@/components/NextButton'
import OTPInput from '@/components/OTPInput'
import API from '@/API'
import Timer from '@/components/Timer'

export default {
    name: 'ForEmployerEmailVerification',
    components: {
        Header,
        Footer,
        'next-button': NextButton,
        'otp-input': OTPInput,
        'timer': Timer
    },
    data(){
        return{
            otp: '',
            email: '',
            timer: 0,
            showTimer: false,
            showResend: false,
            resetTimer: false,
            verified: false,
            otpError: false,
            tries: 0,
        }
    },
    watch: {
        timer: function(newValue){
            if(newValue < 120 && newValue > 0){
                this.showResend = true
            }
            // if value is 180 set resetTimer as false
            if(newValue > 170){
                this.showResend = false
                this.resetTimer = false
            }
        }
    },
    methods: {
        onClickComplete: function(){
            this.$router.push('/for_employers/job_posts')
        },
        onCompleteOtp: function(value){
            if(this.tries <= 2){
                // when 4 digits are completed
                this.otp = value
                // verify otp
                var data = {
                    email: this.email,
                    otp: this.otp
                }
                fetch(API+'/otp/email/validate',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(response => response.json())
                .then(data => {
                    if(data.success === true){
                        this.verified = true
                        this.showTimer = false
                    }else{
                        this.otpError = true
                        this.tries ++
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }else{
                // show error
                this.otpError = true
            }
            
        },
        onChangeOtp: function(value){
            // This function is invoked on each digit input
            this.otp = value
            this.otpError = false
        },
        sendOtp: function(email){
            // send otp
            var data = {
                email: email
            }
            // send otp
            fetch(API+'/otp/email/generate',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.showTimer = true
                    this.resetTimer = true
                    this.tries = 0
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onEndTimer: function(){
            // nothing
        },
        onChangeTimer: function(value){
            this.timer = value
        },
        onClickResend: function(){
            if(this.verified !== true){
                this.sendOtp(this.mob)
            }
        },
        setData: function(options){
            // set options
            this.referralOptions = options
        }
    },computed:{
        activateComplete: function(){
            if(this.verified === true){
                return true
            }{
                return false
            }
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number from query param
        this.$nextTick().then(() => {
            this.email = this.$route.query.email
            // send otp when page loaded
            if(this.email !== undefined){
                this.sendOtp(this.email)
            }
        });

        // if there is no access tken then navigate back to for employers
        if(localStorage.access_token === ''){
            this.$router.push('/for_employers')
        }
    }
}
</script>

<style>
#email_verification{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
.otp_input_wrapper{
    @apply mt-4;
}
#email_verification .heading{
    @apply font-koho text-xl text-mainColor-light uppercase mt-10 mb-5;
    @apply sm:text-base sm:mb-3;
    @apply md:text-lg md:mb-4 md:mt-0;
    @apply md:text-xl lg:mb-7;
}
</style>
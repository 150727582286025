<template>
  <div class="" id="job_preferences">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="job_preferences_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Your job preferences
                </div>
                <div class="left_section_sub_para">
                    Jobs are typically matched based on your profile. Adding your preferences will help improve the matching.               
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="8"/>
                <div id="interests_section">
                    <div class="input_heading">
                        Which job are you most interested in? <span class=" text-inputBorderGray">Choose all that apply.</span>
                    </div>
                    <div class="sections_wrapper">
                        <div id="interests_section_left">
                            <multiple-select :options="interestsOptionsOne" v-on:select="onSelectListOne" :key="0"/>
                        </div>
                        <div id="interests_section_right">
                            <multiple-select :options="interestsOptionsTwo" v-on:select="onSelectListTwo" :key="1"/>
                        </div>
                    </div>
                </div>
                <div id="availability_section">
                    <div class="input_heading">
                        Your availability for work
                    </div>
                    <div class="options_wrapper">
                        <single-select :options="availabilityOptions" v-on:select="onSelectAvailability"/>
                    </div>
                </div>
                <div id="expected_earning_section">
                    <div class="input_heading">
                        What is your monthly earning expectation?
                    </div>
                    <div class="options_wrapper">
                        <single-select-drop-down :options="earningOptions" v-on:selected="onSelectExpectedEarning" placeholder="Choose a range"/>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import SingleSelectTwoRow from '@/components/SingleSelectTwoRow'
import MultipleSelect from '@/components/MultipleSelect'
import SingleSelectDropDown from '@/components/SingleSelectDropDown'
import API from '@/API'

export default {
    name: 'JobPreferences',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'single-select': SingleSelectTwoRow,
        'multiple-select': MultipleSelect,
        'single-select-drop-down': SingleSelectDropDown
    },
    data(){
        return{
            options: [],
            optionsSelectedOne: new Array(0),
            optionsSelectedTwo: new Array(0),
            availabilityOptions: ['Part-time', 'Full-time'],
            availabilityOptionSelected: -1,
            earningOptions: [],
            earningOptionSelected: '',
            mob: 0,
        }
    },
    methods: {
        onClickNext: function(){
            // set preferredjobs
            var preferred_jobs = []
            this.optionsSelectedOne.map((item)=>{
                preferred_jobs.push(this.options[item])
            })
            this.optionsSelectedTwo.map((item)=>{
                preferred_jobs.push(this.options[item + this.interestsOptionsOne.length])
            })
            // set fulltime
            var fulltime = false
            if(this.availabilityOptionSelected === 1){
                fulltime = true
            }else{
                fulltime = false
            }
            // set data
            var data = {
                mobile: parseInt(this.mob),
                preferred_jobs,
                desired_earning: this.earningOptionSelected,
                fulltime
            }
            // save the form 
            fetch(API+'/jobseeker/job_preference',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path:'/jobseeker_register/verification', query:{mob: this.mob}})
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onSelectListOne: function(checked){
            // update optionsSelected based on the checked array
            let customThis = this
            checked.map((item, key)=>{
                // check if the key is already in optionsSelected
                if(customThis.optionsSelectedOne.find(element=>element===key) === undefined && item === true){
                    customThis.optionsSelectedOne.push(key)
                }
                // delete the key if the value become false
                if(customThis.optionsSelectedOne.find(element=>element===key) !== undefined && item === false){
                    customThis.optionsSelectedOne.splice(customThis.optionsSelectedOne.indexOf(key), 1)
                }
            })
        },
        onSelectListTwo: function(checked){
            // update optionsSelected based on the checked array
            let customThis = this
            checked.map((item, key)=>{
                // check if the key is already in optionsSelected
                if(customThis.optionsSelectedTwo.find(element=>element===key) === undefined && item === true){
                    customThis.optionsSelectedTwo.push(key)
                }
                // delete the key if the value become false
                if(customThis.optionsSelectedTwo.find(element=>element===key) !== undefined && item === false){
                    customThis.optionsSelectedTwo.splice(customThis.optionsSelectedTwo.indexOf(key), 1)
                }
            })
        },
        onSelectAvailability: function(selected){
            this.availabilityOptionSelected = selected
        },
        onSelectExpectedEarning: function(selected){
            this.earningOptionSelected = selected
        },
        setOptions: function(preferredJobs, desiredEarnings){
            // set options
            this.options = preferredJobs
            this.earningOptions = desiredEarnings
        }
    },computed:{
        interestsOptionsOne: function(){
            var temp_options = this.options
            return temp_options.slice(0, Math.ceil(this.options.length/2))
        },
        interestsOptionsTwo: function(){
            var temp_options = this.options
            return temp_options.slice(Math.ceil(this.options.length/2), this.options.length)
        },
        activateNext: function(){
           //return true when atleast one industry option selected and availability question aswered and expected earning selected
           if(( this.optionsSelectedOne.length > 0 || this.optionsSelectedTwo.length > 0 ) && this.availabilityOptionSelected !== -1 && this.earningOptionSelected.length > 0){
                return true
           }else{
                return false
           }
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
        });
    },
    beforeRouteEnter (to, from, next) {
        // get the options from backend
        fetch(API+'/render/preferred_job')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.PreferredJob.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var preferredOptions = []
            data.PreferredJob.map((item)=>{
                preferredOptions.push(item.job)
            })

            // get the options from backend
            fetch(API+'/render/desired_earning')
            .then(response => response.json())
            .then(data => {
                // sort the array based on display order
                data.DesiredEarning.sort((a,b)=>{
                    return a.displayOrder - b.displayOrder
                })
                // extract options
                var desiredOptions = []
                data.DesiredEarning.map((item)=>{
                    desiredOptions.push(item.earning)
                })
                // render component
                next(vm => {vm.setOptions(preferredOptions, desiredOptions)})
            })
            .catch((err)=>{
                console.log(err)
            })
        })
        .catch((err)=>{
            console.log(err)
        })
        
    }
}
</script>

<style>
#job_preferences{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
#availability_section {
    @apply mt-6
}
#availability_section .options_wrapper{
    @apply mt-0
}
#interests_section .sections_wrapper{
    @apply flex flex-col md:flex-row
}
#interests_section #interests_section_right{
    @apply ml-0 md:ml-4 lg:ml-8
}
#expected_earning_section{
    @apply mt-6
}
</style>
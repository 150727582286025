<template>
  <div class="" id="work_experience_part_one">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="work_experience_part_one_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Prior work experience
                </div>
                <div class="left_section_sub_para">
                    Lack of work experience will not disqualify you as a candidate.                
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="4"/>
                <div class="" id="previous_work_experience">
                    <div class="input_heading">
                        Do you have previous work experience?
                    </div>
                    <div class="options_wrapper">
                        <single-select :options="previousOptions" v-on:select="onClickPreviousSelect"/>
                    </div>
                </div>
                <div class="" id="nature_work_experience" :class="{hidden: (previousOptionSelected === -1) || (previousOptionSelected === 1)}">
                    <div class="input_heading">
                        What was the nature of your work experience?
                    </div>
                    <div class="options_wrapper">
                        <single-select :options="natureOptions" v-on:select="onClickNatureSelect"/>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import SingleSelect from '@/components/SingleSelect'
import API from '@/API'

export default {
    name: 'WorkExperiencePartOne',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'single-select': SingleSelect
    },
    data(){
        return{
            previousOptions: ['Yes', 'No'],
            previousOptionSelected: -1,
            natureOptions: ['Employed', 'Self employed'],
            natureOptionsSelected: -1,
            mob: 0
        }
    },
    computed: {
        activateNext: function(){
            if(this.previousOptionSelected === 0 && this.natureOptionsSelected !== -1){
                return true
            }else if(this.previousOptionSelected === 1 ){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        onClickNext: function(){
            if(this.previousOptionSelected === 0){
                if(this.natureOptionsSelected === 1){
                    this.$router.push({path:'/jobseeker_register/work_experience/job_details', query:{mob: this.mob, self_employed: true}})
                }else{
                    this.$router.push({path:'/jobseeker_register/work_experience/job_details', query:{mob: this.mob, self_employed: false}})
                }
                
            }else{
                // set data
                var data = {
                    mobile: parseInt(this.mob),
                    work_experience: false,
                    self_employed: false,
                    year: '',
                    industries: []
                }
                // save the form 
                fetch(API+'/jobseeker/work_experience',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(response => response.json())
                .then(data => {
                    if(data.success === true){
                        this.$router.push({path:'/jobseeker_register/language_proficiency/speak', query:{mob: this.mob}})
                    }else{
                        // remove the selection
                        this.optionSelected = -1
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }
            
        },
        onClickPreviousSelect: function(selected){
            this.previousOptionSelected = selected
        },
        onClickNatureSelect: function(selected){
            this.natureOptionsSelected = selected
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
        });
    }
}
</script>

<style>
#work_experience_part_one{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.options_wrapper{
    @apply mt-6;
}
#nature_work_experience{
    @apply mt-8
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
</style>
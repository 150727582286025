<template>
    <div class="" id="for_employers_sign_up">
        <div class="main_heading">
            ARE YOU A BUSINESS?
        </div>
        <div class="sub_heading">
            Find candidates for free
        </div>
        <div class="mobile_input_section">
                <div class="input_heading">
                    Your 10-digit mobile number
                </div>
                <input type="text" class="inputbox placeholder-extralight" v-bind:value="mob" v-on:input="onInputMob" :class="{error_box: errorMob.error}" placeholder="10-digit mobile number">
                <h3 class="error_message_absolute" :class="{hidden: !errorMob.error}">{{errorMob.msg}}</h3>
        </div>
        <div class="contact_consent">
            <custom-checkbox type="checkbox" v-model="consentChecked"/>
            <div :class="{consent_error: errorConsentChecked.error}" id="consent_heading">
                I agree to be contacted via phone or SMS
            </div>
        </div>
        <div class="start_button_section">
            <next-button text="START HERE" :active="true" :onClick="onClickStart"/>
        </div>
    </div>
</template>

<script>
import CustomCheckbox from '@/components/CustomCheckbox'
import NextButton from '@/components/NextButton'

export default {
    name: "ForEmployersSignUp",
    components: {
        'custom-checkbox': CustomCheckbox,
        'next-button': NextButton
    },
    data(){
        return {
            mob: "",
            errorMob: {
                error: false,
                msg: ""
            },
            consentChecked: false,
            errorConsentChecked: {
                error: false
            },
        }
    },
    methods: {
        onClickStart: function(){
            // run validation
            if( this.validateMob() && this.validateChecked() ){
                this.$router.push({path: "/for_employers/verification", query:{mob: this.mob}})
            }
        },
        validateMob: function(){
            if(this.mob === ""){
                this.errorMob.error = true
                this.errorMob.msg = "Enter your mobile number"
                return false
            }else{
                if(isNaN(parseInt(this.mob))){
                    this.errorMob.error = true
                    this.errorMob.msg = "Enter a valid mobile number"
                    return false
                }else if(this.mob.length !== 10){
                    this.errorMob.error = true
                    this.errorMob.msg = "Enter a 10 digit mobile number"
                    return false
                }else{
                    this.errorMob.error = false
                    this.errorMob.msg = ""
                    return true
                }
            }
        },
        validateChecked: function(){
            if(this.consentChecked === false){
                this.errorConsentChecked.error = true
                return false
            }else{
                this.errorConsentChecked.error = false
                return true
            }
        },
        onInputMob: function(e){
            // mobile number input function
            //stop the input when input length reaches 10
            if(e.target.value.replace(/\D/g,'').length <= 10){
                // strip characters
                var strippedMob = e.target.value.replace(/\D/g,'')
                // if the first value is 0, delete it
                if(strippedMob[0] === '0'){
                    // slice the 0 
                    strippedMob =  strippedMob.slice(1)
                }
                this.mob = strippedMob
            }
            // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
            this.$forceUpdate();
        }
    },
    watch: {
        consentChecked: function(){
            // validate when checked change
            this.validateChecked()
        },
        mob: function(){
            // validate mobile number
            this.validateMob()
        }
    }
}
</script>

<style>
#for_employers_sign_up {
    @apply border border-inputBorderGray max-w-sm rounded-sm p-5 py-10 w-full flex flex-col text-center;
    @apply sm:max-w-md;
    @apply md:p-10;
    @apply lg:p-20;
}
#consent_heading{
    @apply font-koho text-xs font-medium ml-3;
}
.consent_error{
    @apply text-customRed !important;
}
.contact_consent{
    @apply flex flex-row mt-10;
}
#for_employers_sign_up .mobile_input_section{
    @apply text-left mt-5;
}
#for_employers_sign_up .main_heading{
    @apply font-koho text-base;
    @apply lg:text-lg;
}
#for_employers_sign_up .sub_heading{
    @apply font-koho text-mainColor-light font-normal text-xl mt-2;
    @apply lg:text-2xl;
}
#for_employers_sign_up .start_button_section{
    @apply mt-5;
}
</style>
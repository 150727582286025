<template>
  <div class="" id="work_experience_part_two">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="work_experience_part_two_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Prior work experience
                </div>
                <div class="left_section_sub_para">
                    Lack of work experience will not disqualify you as a candidate.                
                </div>
                <div class="back_button_section">
                    <back-button/>
                </div>
            </div>
            <div class="right_section">
                <steps :step="4"/>
                <div class="" id="last_job">
                    <div class="input_heading">
                        When did your last job end?
                    </div>
                    <div class="year_select_section">
                        <year-select :startYear="startYear" :endYear="endYear" @selected="onSelectYear"/>
                    </div>
                </div>
                <div class="" id="industries">
                    <div class="input_heading">
                        Which industries have you worked in? <span class="input_heading_sub">Choose all that apply.</span>
                    </div>
                    <div class="industry_select">
                        <searchable-dropdown :options="industryOptions" @select="onSelectIndustry"/>
                    </div>
                </div>
                <div class="next_button_section">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Steps from '@/components/Steps'
import NextButton from '@/components/NextButton'
import BackButton from '@/components/BackButton'
import YearSelect from '@/components/YearSelect'
import SearchableDropDownList from '@/components/SearchableDropDownList'
import API from '@/API'

export default {
    name: 'WorkExperiencePartTwo',
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'back-button': BackButton,
        'year-select': YearSelect,
        'searchable-dropdown': SearchableDropDownList
    },
    data(){
        return{
            industryOptions: [],
            industriesSelected: new Array,
            yearSelected: 0,
            mob: 0,
            selfEmployed: false,
        }
    },
    computed: {
        activateNext: function(){
            if(this.industriesSelected.length > 0 && this.yearSelected !== 0){
                return true
            }
            return false
        },
        startYear: function(){
            // return current year
            var dt = new Date(Date.now())
            return dt.getFullYear()
        },
        endYear: function(){
            // return current year - 20
            var dt = new Date(Date.now())
            return dt.getFullYear() - 20
        }
    },
    methods: {
        onClickNext: function(){
            // set data
            var data = {
                mobile: parseInt(this.mob),
                work_experience: true,
                self_employed: this.selfEmployed,
                year: this.yearSelected,
                industries: this.industriesSelected
            }
            // save the form 
            fetch(API+'/jobseeker/work_experience',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.$router.push({path:'/jobseeker_register/language_proficiency/speak', query:{mob: this.mob}})
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onSelectIndustry: function(value){
            this.industriesSelected = value
        },
        onSelectYear: function(selected){
            this.yearSelected = parseInt(selected)
        },
        setData: function(options){
            // set options
            this.industryOptions = options
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // get mob number and self_employed from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
            if(this.$route.query.self_employed === 'false'){
                this.selfEmployed = false
            }else{
                this.selfEmployed = true
            }
        });
    },
    beforeRouteEnter (to, from, next) {
        // get the options from backend
        fetch(API+'/render/industry')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.Industry.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var options = []
            data.Industry.map((item)=>{
                options.push(item.industry)
            })
            // render component
            next(vm => {vm.setData(options)})
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style>
#work_experience_part_two{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
.options_wrapper{
    @apply mt-6;
}
#industries{
    @apply mt-8
}
.back_button_section{
    @apply hidden;
    @apply sm:block;
}
.year_select_section{
    @apply mt-4
}
.industry_select{
    @apply mt-4
}
</style>
<template>
  <div class="" id="searchable_drop_down_list">
        <v-select :filter="fuseSearch" class="searchable_drop_down_list" :options="options" multiple :clearable="false" v-model="optionsSelected" :components="{OpenIndicator}" placeholder="Select">
            <template #no-options="{}">
                <div class="empty_option">
                    Empty
                </div>
            </template>
        </v-select>
        <div class="selected_wrapper">
            <div class="selected_element" v-for="(option,key) in optionsSelected" :key="key">
                {{option}} 
                <img src="@/assets/images/close.svg" class="selected_close" @click="()=>onClickClose(key)">
            </div>
        </div>
  </div>
</template>

<script>
import OpenIndicator from '@/components/OpenIndicator'
import Fuse from 'fuse.js'

export default {
    name: "SearchableDropDownList",
    props: {
        options: {
            type: Array
        }
    },
    data() {
        return {
            optionsSelected: new Array(0),
            // OpenIndiacator Icon only be used as an rendered element
            OpenIndicator: {
                render: () => <OpenIndicator/> 
            }
        }
    },
    methods: {
        onClickClose: function(key){
            this.optionsSelected.splice(key, 1)
        },
        fuseSearch(options, search) {
            //declare results
            var results = new Array
            const fuse = new Fuse(
                options, 
                {
                    keys: ['label'],
                    includeScore:true,
                    minMatchCharLength:2,
                    shouldSort: true,
                    includeMatches: true,
                    threshold: 0.5
                }
            );
            var tempResults = fuse.search("!^"+search)
            // search for extact match
            tempResults.map((item)=>{
                results.push(item.item)
            })
            // return results
            return results.length
                ? results
                : this.options;
        }
    },
    watch: {
        optionsSelected: function(newValue){
            this.$emit('select', newValue)
        }
    }
}
</script>

<style>
#searchable_drop_down_list{
    @apply max-w-xs;
}
.searchable_drop_down_list .vs__search{
    @apply py-1 pl-2 pr-0 sm:pl-2 sm:py-1 md:py-2.5 md:pl-4;
}
.searchable_drop_down_list .vs__search:focus{
    @apply py-1 pl-2 pr-0 sm:pl-2 sm:py-1 md:py-2.5 md:pl-4;
    @apply font-koho ;
}
.searchable_drop_down_list .vs__selected{
    @apply font-koho hidden;
}
.year_select_dropdown .vs__actions{
    @apply pr-4 sm:pr-2 md:pr-4
}
.searchable_drop_down_list .vs--single.vs--open .vs__selected{
    @apply hidden !important;
}
.searchable_drop_down_list .vs__dropdown-option--highlight{
    @apply bg-mainColor ;
}
.searchable_drop_down_list .vs__dropdown-menu{
    max-height: 200px;
}
.searchable_drop_down_list .vs__dropdown-option{
    @apply font-koho py-1.5 text-sm md:text-base lg:text-base;
}
.searchable_drop_down_list .vs__search::placeholder{
    @apply font-koho text-base text-inputBorderGray font-extralight;
}
.selected_wrapper{
    @apply mt-2 w-full ;
}
.selected_element{
    @apply inline-flex flex-row items-center w-auto px-2 py-2 my-1 mx-1 rounded-sm text-white bg-mainColor opacity-60 text-sm font-koho;
}
.selected_close{
    @apply ml-2 w-3.5 h-3.5;
}
.selected_close:hover{
    @apply cursor-pointer;
}
.empty_option{
    @apply font-koho text-base text-inputBorderGray
}
.filtered_option_element{

}
</style>
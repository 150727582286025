<template>
    <div class="job_post">
        <button @click="onClick" class="delete">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 28 32">
                <path id="trash-alt" d="M18.5,27h1a.5.5,0,0,0,.5-.5V9.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v17A.5.5,0,0,0,18.5,27Zm-10,0h1a.5.5,0,0,0,.5-.5V9.5A.5.5,0,0,0,9.5,9h-1a.5.5,0,0,0-.5.5v17A.5.5,0,0,0,8.5,27Zm19-23H21L18.9,1.2A3,3,0,0,0,16.5,0h-5A3,3,0,0,0,9.1,1.2L7,4H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,6H2V29a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V6h1.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,27.5,4ZM10.7,2.4a1.006,1.006,0,0,1,.8-.4h5a1.006,1.006,0,0,1,.8.4L18.5,4h-9ZM24,29a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V6H24ZM13.5,27h1a.5.5,0,0,0,.5-.5V9.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v17A.5.5,0,0,0,13.5,27Z" fill="#fff"/>
            </svg>
        </button>
        <div class="content">
            <button class="heading" :class="{active_heading: active, inactive_heading: !active}" @click="onClickButton">
                {{heading}}
            </button>
            <div class="date">
                Posted on {{postedOn}}
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'JobPost',
    props: {
        onClickDelete: {
            type: Function
        },
        link: {
            type: String
        },
        heading: {
            type: String
        },
        date: {
            type: Date
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick: function(){
            // run the props code
            this.$emit("onClickDelete", '')
        },
        onClickButton: function(){
            this.$emit("onClickButton", '')
        }
    },
    computed: {
        postedOn: function(){
            return this.date.toDateString()
        }
    }
}
</script>

<style>
.job_post{
    @apply flex flex-row items-center;
    @apply mt-5;
}
.content{
    @apply ml-5;
}
.delete{
    @apply w-10 h-10 p-1 bg-customRed rounded focus:outline-none flex justify-center items-center hover:bg-customRedBorder active:bg-customRed-light;
}
.job_post .heading{
    @apply font-koho font-medium text-mainColor focus:outline-none focus:text-customRed;
    @apply text-sm;
    @apply lg:text-base;
}
.date{
    @apply font-koho text-xs;
    @apply lg:text-sm;
}
.active_heading{
    @apply text-customRed !important
}
.inactive_heading{
    @apply text-mainColor !important
}
</style>
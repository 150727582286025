<template>
    <div id="privacy_policy">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div id="privacy_policy_content">
            <div id="main_heading">
                Privacy Policy
            </div>
            <div id="content_wrapper">
                <div class="content_block">
                    <div class="text"> 
                        At Strong United, accessible from www.strongunited.org, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Strong United, and how we use it.
                        <br><br>
                        If you have additional questions or require more information about our Privacy Policy, contact us at stgutd@gmail.com.
                        <br><br>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they share with and / or collect from Strong United. This policy is not applicable to any information collected offline or via channels other than this website.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        CONSENT
                    </div>
                    <div class="text">
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        INFORMATION WE COLLECT
                    </div>
                    <div class="text">
                        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                        <br><br>
                        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and / or attachments you may send us, and any other information you may choose to provide.
                        <br><br>
                        When you register for an Account, we may ask for your contact information, including information such as your name, company name, address, email address, and telephone number.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        HOW WE USE YOUR INFORMATION
                    </div>
                    <div class="text">
                        We use the information we collect in various ways, including to:
                        <br><br>
                        - Provide, operate, and maintain our website
                        <br><br>
                        - Improve, personalize, and expand our website
                        <br><br>
                        - Understand and analyze how you use our website
                        <br><br>
                        - Develop new products, services, features, and functionality
                        <br><br>
                        - Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
                        <br><br>
                        - Send you emails
                        <br><br>
                        - Find and prevent fraud
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        LOG FILES
                    </div>
                    <div class="text">
                        Strong United follows a standard procedure of using log files. These files log visitors when they visit websites.
                         All hosting companies do this as part of hosting services’ analytics.
                          The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring / exit pages, and possibly the number of clicks.
                           These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        COOKIES AND WEB BEACONS
                    </div>
                    <div class="text">
                        Like any other website, Strong United uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and / or other information.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        ADVERTISING PARTNERS PRIVACY POLICIES
                    </div>
                    <div class="text">
                        We currently do not display third-party advertisements on our website. In the future, if we choose to publish any third-party ad, the following terms shall apply:
                        <br><br>
                        Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Strong United, which are sent directly to users’ browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and / or to personalize the advertising content that you see on websites that you visit.
                        <br><br>
                        Note that Strong United has no access to or control over these cookies that are used by third-party advertisers.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        THIRD PARTY PRIVACY POLICIES
                    </div>
                    <div class="text">
                        Strong United’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. 
                        <br><br>
                        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        GDPR DATA PROTECTION RIGHTS
                    </div>
                    <div class="text">
                        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                        <br><br>
                        <span class="text_bold">- The right to access:</span>  You have the right to request copies of your personal data. We may charge you a small fee for this service.
                        <br><br>
                        <span class="text_bold">- The right to rectification:</span> You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                        <br><br>
                        <span class="text_bold">- The right to erasure:</span> You have the right to request that we erase your personal data, under certain conditions.
                        <br><br>
                        <span class="text_bold">- The right to restrict processing:</span> You have the right to request that we restrict the processing of your personal data.
                        <br><br>
                        The right to object to processing: You have the right to object to our processing of your personal data, under certain conditions.
                        <br><br>
                        <span class="text_bold">- The right to data portability:</span> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                        <br><br>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        CHILDREN’S INFORMATION
                    </div>
                    <div class="text">
                        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                        <br><br>
                        Strong United does not knowingly collect any Personal Identifiable Information from children under the age of 15. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </div>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
    name: 'PrivacyPolicy',
    components: {
        Header,
        Footer
    }
}
</script>

<style>
#privacy_policy{
    @apply flex flex-col min-h-screen;
}
#privacy_policy_content{
    @apply h-auto w-full flex flex-col items-center px-5;
    @apply sm:px-10;
    @apply lg:px-20;
}
#main_heading{
    @apply mt-32 font-koho text-4xl;
    @apply md:text-6xl md:mt-40;
    @apply lg:text-7xl;
}
#content_wrapper{
    @apply w-full bg-mainColor-superlight rounded-sm mt-10 px-5;
    @apply sm:px-10 sm:py-5;
    @apply md:mt-16;
    @apply lg:px-20 lg:py-14 lg:mt-20;
}
.content_block{
    @apply py-5;
    @apply md:py-7;
}
.text{
    @apply font-koho;
    @apply md:text-lg;
}
.text_bold{
    @apply font-semibold
}
.sub_heading{
    @apply font-koho font-semibold mb-3;
    @apply md:text-lg;
}
</style>
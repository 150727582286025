<template>
    <div id="terms_of_use">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div id="terms_of_use_content">
            <div id="main_heading">
                Terms Of Use
            </div>
            <div id="content_wrapper">
                <div class="content_block">
                    <div class="text"> 
                        The Strong United (“Association”) website (the “Site”) located at www.strongunited.org is a copyrighted work belonging to Strong United. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.
                        <br><br>
                        All such additional terms, guidelines, and rules are incorporated by reference into these Terms.
                        <br><br>
                        These Terms of Use (“Terms”) describe the legally binding terms and conditions that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND / OR USE THE SITE.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        ACCESS TO THE SITE
                    </div>
                    <div class="text">
                        <br>
                        <span class="text_bold">Subject to these Terms:</span>
                        <br><br>
                        The Association grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use.
                        <br><br>
                        <span class="text_bold">Certain Restrictions: </span>
                        <br><br>
                        The rights approved to you in these Terms are subject to the following restrictions: 
                        <br><br>
                        (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; 
                        <br><br>
                        (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site;
                        <br><br>
                        (c) you shall not access the Site in order to build a similar or competitive website; and 
                        <br><br>
                        (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site must be retained on all copies thereof.
                        <br><br>
                        The Association reserves the right to change, suspend, or cease the Site with or without notice to you. You approve that the Association will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part.
                        <br><br>
                        <span class="text_bold">No Support or Maintenance: </span>
                        <br><br>
                        You agree that the Association will have no obligation to provide you with any support in connection with the Site.
                        <br><br>
                        Excluding any User Content that you may provide, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by the Association. Note that these Terms and access to the Site do not give you any rights, title or interest in or to any intellectual property rights, except for the limited access rights expressed in this section. Association reserves all rights not granted in these Terms.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        THIRD PARTY LINKS AND ADS; OTHER USERS
                    </div>
                    <div class="text">
                        <br>
                        <span class="text_bold">Third Party Links & Ads: </span>
                        The Site may contain links to third-party websites and services, and / or display advertisements for third parties. Such third party links and ads are not under the control of the Association, and the Association is not responsible for any third party links and ads. The Association provides access to these third party links and ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to third party links and ads. You use all third party links and ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the third party links and ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.
                        <br><br>
                        <span class="text_bold">Other Users: </span>
                        <br><br>
                        Each Site user is solely responsible for any and all of its own User Content.  Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.  You agree that the Association will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Site user, we are under no obligation to become involved.
                        <br><br>
                        You hereby release and forever discharge the Association and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site. 
                        <br><br>
                        <span class="text_bold">Cookies and Web Beacons: </span>
                        <br><br>
                        Like any other website, Strong United uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the user’s experience by customizing our web page content based on visitors’ browser type and / or other information.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        DISCLAIMERS
                    </div>
                    <div class="text">
                        The Site is provided on an “as-is” and “as available” basis, and the Association and its suppliers expressly disclaim any and all warranties and conditions of any kind, whether expressed, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. The Association and its suppliers make no guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.
                        <br><br>
                        Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        LIMITATION OF LIABILITY
                    </div>
                    <div class="text">
                        To the maximum extent permitted by law, in no event shall the Association or its suppliers be liable to you or any third party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these Terms or your use of, or incapability to use the Site, even if the Association has been advised of the possibility of such damages. Access to and use of the Site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.
                        <br><br>
                        To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, the Association’s liability to you for any damages arising from or related to this agreement, will at all times be limited to a maximum of fifty U.S. dollars (USD50). The existence of more than one claim will not enlarge this limit. You agree that the Association’s suppliers will have no liability of any kind arising from or relating to this agreement.
                        <br><br>
                        Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.
                        <br><br>
                        Term and Termination: Subject to this Section, these Terms will remain in full force and effect while you use the Site. The Association may suspend or terminate your rights to use the Site at any time for any reason at its sole discretion, including for any use of the Site in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately. You understand that any termination of your Account may involve deletion of your User Content associated with your Account from the live databases.  The Association will not have any liability whatsoever to you for any termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the relevant provisions of these Terms will remain in effect.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        COPYRIGHT POLICY
                    </div>
                    <div class="text">
                        The Association respects the intellectual property of others and asks that users of our Site do the same. In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification must be provided to us:
                        <br><br>
                        - Identification of the copyrighted work(s) that you claim to have been infringed;
                        <br><br>
                        - Identification of the material on our services that you claim is infringing and that you request us to remove;
                        <br><br>
                        - Sufficient information to permit us to locate such material;
                        <br><br>
                        - Your name, telephone number, and e-mail address; and
                        <br><br>
                        - A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        GENERAL
                    </div>
                    <div class="text">
                        These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an email to the last email address you provided to us and / or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current email address. In the event that the last email address that you have provided us is not valid, our dispatch of the email containing such notice will nonetheless constitute effective notice of the changes described in the notice. Any changes to these Terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an email notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site. These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. 
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        DISPUTE RESOLUTION
                    </div>
                    <div class="text">
                        <span class="text_bold">Applicability of Arbitration Agreement:</span> All claims and disputes in connection with the Terms or the use of any product or service provided by the Association that cannot be resolved informally or shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed to, all arbitration proceedings shall be held in English. This Arbitration Agreement applies to you and the Association, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.
                        <br><br>
                        <span class="text_bold">Notice Requirement and Informal Dispute Resolution:</span> Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute describing the nature and basis of the claim or dispute, and the requested relief. A Notice to the Association should be sent to Bangalore, India. After the Notice is received, you and the Association may attempt to resolve the claim or dispute informally. If you and the Association do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award to which either party is entitled.
                        <br><br>
                        <span class="text_bold">Arbitration Rules:</span> Any dispute, controversy or claim referred to and decided by a Sole Arbitrator appointed and nominated by the Association and you by agreeing to the terms and conditions herein consent to the Arbitration. The arbitration shall be conducted in Bangalore, India and judgment on the arbitration award may be entered in any court having jurisdiction thereof.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        ELECTRONIC COMMUNICATIONS
                    </div>
                    <div class="text">
                        The communications between you and the Association use electronic means, whether you use the Site or send us emails, or whether Association posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Association in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that the Association provides to you electronically satisfy any legal obligation that such communications would satisfy if it were be in a hard copy writing.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        ENTIRE TERMS
                    </div>
                    <div class="text">
                        These Terms constitute the entire agreement between you and the Association regarding the use of the Site. The Association’s failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word "including" means "including without limitation". If any provision of these Terms is held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Your relationship to the Association is that of an independent contractor, and neither party is an agent or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without the Association’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The Association may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.   
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        YOUR PRIVACY
                    </div>
                    <div class="text">
                        Please read our Privacy Policy.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        COPYRIGHT AND TRADEMARK INFORMATION
                    </div>
                    <div class="text">
                        Copyright ©. All rights reserved.  All trademarks, logos and service marks displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.
                    </div>
                </div>
                <div class="content_block">
                    <div class="sub_heading">
                        CONTACT INFORMATION
                    </div>
                    <div class="text">
                        Address: Bangalore, India
                        <br>
                        Email: stgutd@gmail.com
                    </div>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
    name: 'TermsOfUse',
    components: {
        Header,
        Footer
    }
}
</script>

<style>
#terms_of_use{
    @apply flex flex-col min-h-screen;
}
#terms_of_use_content{
    @apply h-auto w-full flex flex-col items-center px-5;
    @apply sm:px-10;
    @apply lg:px-20;
}
#main_heading{
    @apply mt-32 font-koho text-4xl;
    @apply md:text-6xl md:mt-40;
    @apply lg:text-7xl;
}
#content_wrapper{
    @apply w-full bg-mainColor-superlight rounded-sm mt-10 px-5;
    @apply sm:px-10 sm:py-5;
    @apply md:mt-16;
    @apply lg:px-20 lg:py-14 lg:mt-20;
}
.content_block{
    @apply py-5;
    @apply md:py-7;
}
.text{
    @apply font-koho;
    @apply md:text-lg;
}
.text_bold{
    @apply font-semibold
}
.sub_heading{
    @apply font-koho font-semibold mb-3;
    @apply md:text-lg;
}
</style>
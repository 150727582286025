<template>
  <div class="job_details_form">
      <div class="">
            <div class="heading">
                Job title <span class=" text-inputBorderGray font-extralight">50 characters or less</span>
            </div>
            <input type="text" class="inputbox" id="title_input" placeholder="Job title to be displayed" v-model="title" :class="{error_box: errorTitle.error}">
            <h3 class="error_message_absolute" :class="{hidden: !errorTitle.error}">{{errorTitle.msg}}</h3>
      </div>
      <div class="input_section">
          <div class="heading">
              Monthly gross <span class=" text-inputBorderGray font-extralight">salary Range</span>
          </div>
          <div class="pt-2"></div>
          <drop-down :options="earningOptions" v-on:selected="onSelectSalary" placeholder="Choose a range"/>
      </div>
      <div class="input_section">
          <div class="heading">
              Industry your business is in <span class="text-inputBorderGray font-extralight">Choose all that apply.</span>
          </div>
            <div class="industry_select">
                <searchable-dropdown :options="industryOptions" @select="onSelectIndustry"/>
            </div>
      </div>
      <div class="input_section">
            <div class="heading">
                Copy-paste your job description here <span class="text-inputBorderGray font-extralight">2000 characters or less</span>
            </div>
            <div class="pt-2"></div>
            <textarea class="multiline" v-model="description" placeholder="Add job description here"></textarea>
      </div>
      <div class="post_job_button">
          <post-job-button :onClick="onClickPost" :active="activatePostButton" text="POST JOB"/>
      </div>
  </div>
</template>

<script>
import NextButton from '@/components/NextButton'
import SingleSelectDropDown from '@/components/SingleSelectDropDown'
import SearchableDropDownList from '@/components/SearchableDropDownList'
import API from '@/API'

export default {
    name: "JobDetailsForm",
    components: {
        'post-job-button': NextButton,
        'drop-down': SingleSelectDropDown,
        'searchable-dropdown': SearchableDropDownList
    },
    data(){
        return {
            title: '',
            errorTitle: {
                error: false,
                msd: ''
            },
            earningOptions: [],
            earningOptionSelected: '',
            industryOptions: [],
            industriesSelected: new Array,
            description: '',
        }
    },
    computed: {
        activatePostButton: function(){
            if(
                !this.errorTitle.error && 
                this.earningOptionSelected.length !== 0 && 
                this.description.length !== 0 && 
                this.industriesSelected.length !== 0)
            {
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        validateTitle: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.title === ""){
                this.errorTitle.error = true
                this.errorTitle.msg = "Enter job title"
                return false
            }else if(specialCharacterFormat.test(this.title) || digitFormat.test(this.title)){
                this.errorTitle.error = true
                this.errorTitle.msg = "Enter a valid job title"
                return false
            }else{
                this.errorTitle.error = false
                this.errorTitle.msg = ""
                return true
            }
        },
        onSelectSalary: function(selected){
            this.earningOptionSelected = selected
        },
        onSelectIndustry: function(value){
            this.industriesSelected = value
        },
        onClickPost: function(){
            if(
                !this.errorTitle.error && 
                this.earningOptionSelected.length !== 0 && 
                this.description.length !== 0 && 
                this.industriesSelected.length !== 0)
            {
                // set data
                let data = {
                    title: this.title,
                    monthly_salary: this.earningOptionSelected,
                    description: this.description,
                    industries: this.industriesSelected
                }

                // emit on save event
                this.$emit("onSave", data)
            }
        },
        setOptions: function(desiredEarnings){
            // set options
            this.earningOptions = desiredEarnings
        }
    },
    watch: {
        title: function(){
            this.validateTitle()
        }
    },
    beforeMount () {
        // get the options from backend
        fetch(API+'/render/desired_earning')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.DesiredEarning.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var desiredOptions = []
            data.DesiredEarning.map((item)=>{
                desiredOptions.push(item.earning)
            })
            // render component
            this.setOptions(desiredOptions)
        })
        .catch((err)=>{
            console.log(err)
        })

        // get the options from backend
        fetch(API+'/render/industry')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.Industry.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // extract options
            var options = []
            data.Industry.map((item)=>{
                options.push(item.industry)
            })
            // render component
            this.industryOptions = options
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style>
.post_job_button{
    @apply max-w-xs mt-10
}
.job_details_form .input_section{
    @apply mt-10
}
.multiline{
    @apply w-full border border-inputBorderGray rounded h-44 focus:outline-none p-2 font-koho
}
.multiline::placeholder{
    @apply font-koho text-lg font-extralight text-inputBorderGray
}
</style>